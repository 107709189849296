import { useState, useEffect } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import iconCross from "assets/Icons/cross.svg";
import styles from "./RawContentVisializerModal.module.css";
//@ts-ignore
import ClaimData from "./ClaimData/ClaimData";
import {
  getClaims,
  findTxExecutionTree,
  //@ts-ignore
} from "uml_f81307e07a11f1d78c0c8cf2cab553c30253b623c6a927a6d30ede7d07cc815b";
import { REACT_APP_API_ENDPOINT } from "../../conf";

type Props = {
  handleClose: () => void;
  open: boolean;
  network: string;
  txId: string;
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  borderRadius: "14px",
  width: "90%",
  height: "90%",
  boxShadow: " 0 8px 25px 0 rgba(0, 0, 0, 0.31)",
  color: "#fff",
  padding: "20px",
  backgroundColor: "#2d2e57",
};
const RawContentVisializerModal = (props: Props) => {
  const [txTree, setTxTree] = useState({});

  useEffect(() => {
    (async () => {
      if (props.network && props.txId) {
        //@ts-ignore
        const dataGet = await getClaims(props.network, REACT_APP_API_ENDPOINT, [
          {
            issuer: "TxLogProvider",
            keyFirstPart: { txid: props.txId },
            keySecondPart: null,
            startsAtKeySecondPart: null,
            endsAtKeySecondPart: null,
          },
        ]);
        const tree = await findTxExecutionTree(
          dataGet,
          props.network,
          props.network,
          //@ts-ignore
          REACT_APP_API_ENDPOINT
        );
        setTxTree({
          tree,
          find: props.txId,
        });
      }
    })();
  }, [props]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={props.open}
      onClose={props.handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <Fade in={props.open}>
        <Box sx={style}>
          <div className={styles.header}>
            <div onClick={props.handleClose}>
              <img style={{ cursor: "pointer" }} src={iconCross} alt="" />
            </div>
          </div>
          <div className={styles.headerBody}>
            <ClaimData txTree={txTree} planumlSvg={[]} />
          </div>
          <div className={styles.headersblockList}></div>
        </Box>
      </Fade>
    </Modal>
  );
};
export default RawContentVisializerModal;
